import instance2 from "../Utils/instance2"

export const getUsersClean = body => {
  return instance2({
    method: 'get',
    url: 'users/clean',
    params: body
  })
}

export const getUserDocumentCategories = body => {
  return instance2({
    method: 'get',
    url: 'settings/users_documents/get_category.php',
    params: body
  })
}

export function getUserByRut(body) {
  return instance2.get('users/get_by_rut', { params: body })
}

export const uploadFile = body => {
  return instance2({
    method: 'post',
    url: 'upload_file',
    data: body
  })
}

/**
 * Uploads a file in raw format.
 * 
 * @param {File} file - The file to be uploaded.
 * @returns {Promise} A promise that resolves when the file is uploaded.
 */
export function uploadFileRaw(file, path = null) {
  const body = new FormData()
  body.append('file', file)
  body.append('name', file.name)
  if (!!path) body.append('path', path)
  return uploadFile(body)
}

export const getBaseActivities = () => {
  return instance2({
    method: 'get',
    url: 'users/base_activities'
  })
}

export const getUserBaseActivitiesReport = (body) => {
  return instance2({
    method: 'get',
    url: 'user_base_activities/report',
    params: body
  })
}

export const createBaseActivity = (body) => {
  return instance2({
    method: 'post',
    url: 'users/base_activities/create',
    data: body
  })
}
export const editBaseActivity = (body) => {
  return instance2({
    method: 'post',
    url: 'users/base_activities/edit',
    data: body
  })
}

export const deleteBaseActivity = (body) => {
  return instance2({
    method: 'post',
    url: 'users/base_activities/delete',
    data: body
  })
}

export const getBaseActivitiesReport = body => {
  return instance2({
    method: 'get',
    url: 'users/base_activities/report',
    params: body
  })
}

export const startUserActivity = body => {
  return instance2({
    method: 'post',
    url: 'users/base_activities/start',
    data: body
  })
}

export const createUserActivityRegister = body => {
  return instance2({
    method: 'post',
    url: 'users/base_activities/create/register',
    data: body
  })
}

export const getPositions = (body) => {
  return instance2({
    method: 'get',
    url: 'positions',
    params: body
  })
}

export const createPosition = (body) => {
  return instance2({
    method: 'post',
    url: 'users/positions',
    data: body
  })
}

export function editPosition(body) {
  return instance2.put('users/positions', body)
}

export function deletePosition(body) {
  return instance2.delete('users/positions', { data: body })
}

export const getParticipations = body => {
  return instance2({
    method: 'get',
    url: 'users/participations',
    params: body
  })
}

export const usersReportAmount = body => {
  return instance2({
    method: 'post',
    url: 'users/amount_report',
    data: body
  })
}

export const usersReportPercent = body => {
  return instance2({
    method: 'post',
    url: 'users/percent_report',
    data: body
  })
}

export const userComplianceReport = body => {
  return instance2({
    method: 'get',
    url: 'users/compliance_report',
    params: body
  })
}

export const sendRecover = body => {
  return instance2({
    method: 'post',
    url: 'users/password/send',
    data: body
  })
}

export const getUserFromCode = body => {
  return instance2({
    method: 'get',
    url: 'users/from_code',
    params: body
  })
}

export const setNewPassword = body => {
  return instance2({
    method: 'post',
    url: 'users/password/new',
    data: body
  })
}

export const getTableUsers = (body, signal) => {
  return instance2({
    method: 'get',
    url: 'users/table',
    params: body,
    signal
  })
}

export function activateUser(body) {
  return instance2.put('users/activate', body)
}

export function deactivateUser(body) {
  return instance2.put('users/deactivate', body)
}

export const uploadExcel = body => {
  return instance2({
    method: 'post',
    url: 'users/massive',
    data: body
  })
}

export const getSupervised = body => {
  return instance2({
    method: 'get',
    url: 'users/supervised',
    params: body
  })
}

export const getSupervisedData = body => {
  return instance2({
    method: 'get',
    url: 'users/supervised/data',
    params: body
  })
}

export const getSupervisedDetails = body => {
  return instance2({
    method: 'get',
    url: 'users/supervised/details',
    params: body
  })
}

export const getCurrentUser = body => {
  return instance2({
    method: 'get',
    url: 'user',
    params: body
  })
}

export const getUsersIds = body => {
  return instance2({
    method: 'get',
    url: 'users/manager'
  })
}

export const getUsersbyIds = body => {
  return instance2({
    method: 'get',
    url: 'users/ids',
    params: body
  })
}

export const getDocumentationReport = body => {
  return instance2({
    method: 'get',
    url: 'users/documentation/report',
    params: body
  })
}

export function getFingerprintReport(body) {
  return instance2.get('users/documentation/fingerprint', { params: body })
}

export const getDocumentationCompliance = body => {
  return instance2({
    method: 'get',
    url: 'users/documentation/compliance',
    params: body
  })
}

export const getDocumentationByDocumentReport = body => {
  return instance2({
    method: 'get',
    url: 'users/documentation/documents',
    params: body
  })
}

export const getPendings = body => {
  return instance2({
    method: 'get',
    url: 'pendings',
    params: body,
    signal: body?.signal
  })
}

export const getOverduePendings = body => {
  return instance2({
    method: 'get',
    url: 'pendings/overdue',
    params: body,
    signal: body?.signal
  })
}

export const getActivePendings = body => {
  return instance2({
    method: 'get',
    url: 'pendings/active',
    params: body,
    signal: body?.signal
  })
}

export const getFuturePendings = body => {
  return instance2({
    method: 'get',
    url: 'pendings/future',
    params: body,
    signal: body?.signal
  })
}

export const getOtherBranchesPendings = body => {
  return instance2({
    method: 'get',
    url: 'pendings/other_branches',
    params: body,
    signal: body?.signal
  })
}

export function getDiploma(body) {
  return instance2.get('users/diploma', { params: body, responseType: 'blob' })
}

export function getProfile(body) {
  return instance2.get('users/profile', { params: body })
}

export function updateProfile(body) {
  return instance2.put('users/profile', body)
}

export function addUserSign(body) {
  return instance2.post('users/sign', body)
}

export function removeUserSign(body) {
  return instance2.delete('users/sign', { params: body })
}

export function getUserParticipations(body) {
  return instance2.get('users/profile_participations', { params: body })
}

export function sendUserWelcome(body) {
  return instance2.get('users/send_welcome', { params: body })
}

export function changePassword(body) {
  return instance2.post('users/password/update', body)
}

export function changeEmail(body) {
  return instance2.post('users/email/update', body)
}

export function verifyPassword(body) {
  return instance2.get('users/password/verify', { params: body })
}

export function getUserChangelog(body) {
  return instance2.get("users/changelogs", { params: body })
}

export function checkExisting(body) {
  return instance2.get("users/check_existing", { params: body })
}

export function checkRut(body) {
  return instance2.get("users/check_rut", { params: body })
}

export function getUserEvaluations(body) {
  return instance2.get("users/evaluations", { params: body })
}

export function syncWithBuk(signal) {
  return instance2.post("buk/sync", {}, { signal })
}

export function testBuk(body) {
  return instance2.get("buk/run", { params: body })
}

export function checkBuk() {
  return instance2.get("buk/check")
}

export function uploadDocumentToBuk(body) {
  return instance2.post("buk/document", body)
}

export function changePermissions(body) {
  return instance2.post("users/change_permissions", body)
}

export function removeUserFromBaseActivity(body) {
  return instance2.post("users/base_activities/remove", body)
}

export function getUserEppDeliveries(body) {
  return instance2.get("users/epp_deliveries", { params: body })
}

export function setFingerprintURL(body) {
  return instance2.put("users/finger", body)
}

export function getUserAreas(body) {
  return instance2.get("users/areas", { params: body })
}

export function assignUserAreas(body) {
  return instance2.put("users/areas", body)
}

export function assignUserCertificate(body) {
  return instance2.put("users/certificate", body)
}

export function sendVerifyEmail(body) {
  return instance2.post("users/verify_email", body)
}

export function verifyEmail(body) {
  return instance2.get("users/email_verification/verify", { params: body })
}
